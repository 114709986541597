import { Duration } from '@bufbuild/protobuf'
import {
  addMinutes,
  format,
  getHours,
  isSameDay,
  isSameMonth,
  isSameYear,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns'

import { DEFAULT_TIMEZONE } from '@/lib/utils/constants'

const timeToDate = (str: string) => {
  const [hours, minutes] = str.split(':')
  const date = new Date()
  date.setHours(Number(hours))
  date.setMinutes(Number(minutes))
  return date
}

const dateToTime = (date: Date): string => {
  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
}

export const newEndTime = (newStartTime: string): string => {
  const newStartDate = timeToDate(newStartTime)

  const newEndTime = addMinutes(newStartDate, 30)

  return dateToTime(newEndTime)
}

export const combineToDate = (
  dateString: string,
  timeString?: string,
): Date | undefined => {
  if (!timeString) {
    return
  }

  const [hours, minutes] = (timeString || '').split(':').map(Number)

  const result = setSeconds(
    setMinutes(setHours(new Date(dateString), hours), minutes),
    0,
  )

  return result
}

export const formatEventTimeRange = (
  startDate?: string,
  endDate?: string,
  startTime?: string,
  endTime?: string,
): string | undefined => {
  if (!startDate || !endDate || !startTime || !endTime) return undefined
  const startDateTime = combineToDate(startDate, startTime)
  const endDateTime = combineToDate(endDate, endTime)

  if (!startDateTime || !endDateTime) {
    return
  }

  const sameDate = startDate === endDate
  const dateFormat = sameDate ? 'EEE, MMM do' : 'EEE, MMM do, ha'
  const timeRange = sameDate
    ? ` ${format(startDateTime, 'ha')} - ${format(endDateTime, 'ha')}`
    : ''

  return `${format(startDateTime, dateFormat)}${timeRange}`
}

export const formatEventTime = (
  datetime?: string | Date,
): string | undefined => {
  if (!datetime) return undefined
  return format(new Date(datetime), 'h:mm aaa')
}

export const getTimezone = () => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (!timezone) {
    timezone = DEFAULT_TIMEZONE
  }

  return timezone
}

export const getLanguage = () => {
  let language = Intl.DateTimeFormat().resolvedOptions().locale

  if (!language) {
    language = 'en-US'
  }

  return language
}

export const formatDuration = (duration: Duration | undefined): string => {
  const totalSeconds = duration?.seconds ? Number(duration.seconds) : 0

  if (totalSeconds < 60) {
    return `${totalSeconds} seconds`
  }

  const minutes = Math.floor(totalSeconds / 60)
  if (minutes < 60) {
    return `${minutes} minutes`
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    return `${hours} hours`
  }

  const days = Math.floor(hours / 24)
  return `${days} days`
}

export const formatDateRange = (startDate: Date, endDate: Date): string => {
  const isCurrentYear = startDate.getFullYear() === new Date().getFullYear()

  if (isSameDay(startDate, endDate)) {
    const startHour = getHours(startDate)
    const endHour = getHours(endDate)
    const isSameMeridiem =
      (startHour < 12 && endHour < 12) || (startHour >= 12 && endHour >= 12)
    if (isSameMeridiem) {
      return `${format(startDate, isCurrentYear ? "EEE, MMM do 'at' h:mm" : "EEE, MMM do, yyyy 'at' h:mm")} - ${format(endDate, 'h:mm aaa')}`
    } else {
      return `${format(startDate, isCurrentYear ? "EEE, MMM do 'at' h:mm aaa" : "EEE, MMM do, yyyy 'at' h:mm aaa")} - ${format(endDate, 'h:mm aaa')}`
    }
  }

  if (isSameMonth(startDate, endDate) && isSameYear(startDate, endDate)) {
    return `${format(startDate, 'EEE')} - ${format(endDate, 'EEE')}, ${format(startDate, 'MMM do')} - ${format(endDate, isCurrentYear ? 'd' : 'd, yyyy')}`
  }

  if (isSameYear(startDate, endDate)) {
    return `${format(startDate, 'MMM do')} - ${format(endDate, isCurrentYear ? 'MMM do' : 'MMM do, yyyy')}`
  }

  // Always show years when they're different
  return `${format(startDate, 'MMM do, yyyy')} - ${format(endDate, 'MMM do, yyyy')}`
}
