import { customAlphabet } from 'nanoid'
import slugify from 'slugify'

export const generateId = () => {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789_-', 8)
  return nanoid()
}

export const kebabCase = (value: string) => {
  return slugify(value, {
    lower: true, // convert to lower case
    strict: true, // strip special characters except replacement
    locale: 'vi', // language code of the locale to use
    trim: true, // trim leading and trailing replacement chars
  })
}

export const getInitials = (fullName?: string | undefined | null) => {
  const cleanValue = (fullName || '').replace(/[^a-zA-Z0-9\s]/g, '')
  const initials = cleanValue
    .split(' ')
    .map((name) => name.charAt(0))
    .join('')
  return initials.slice(0, 2)
}

/**
 * Use this utility class to omit text that have parenthesis
 *
 * John Smith (jj_smith_122) => John Smith
 * Jerry (The Bomb) Keller => Jerry Keller
 *
 * @param text
 */
export const omitParenthesisText = (text: string) => {
  // Define a regular expression to match text inside parentheses
  const regex = /\([^)]*\)/g

  // Use the replace method to replace all occurrences of text inside parentheses with an empty string
  return text.replace(regex, '').trim()
}

export const obfuscateKey = (
  key: string,
  showStart: number = 0,
  showEnd: number = 4,
  maxObfuscated: number = 8,
) => {
  const start = key.slice(0, showStart)
  const end = key.slice(-showEnd)
  const length = key.length
  if (length <= showStart + showEnd) {
    return key
  }
  const obfuscated = '*'.repeat(
    Math.min(length - showStart - showEnd, maxObfuscated),
  )
  return `${start}${obfuscated}${end}`
}

export const getNameFromEmail = (email: string): string | null => {
  // Use a regular expression to match the first alphanumeric word
  const match = email.match(/\b[a-zA-Z0-9]+\b/)
  return match ? match[0] : null
}
